import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { VStack } from '@chakra-ui/react'
import { useOverrideComponentPropsContext } from '../lib/override-component-props'
import GrandText from './GrandText'
import { GrandDivider, type GrandDividerProps } from './GrandDivider'

export interface IHoverableTextUnderlineProps extends StackProps {
  text: string
  isHovered: boolean
  hoveredBorderColor?: GrandDividerProps['borderColor']
}

const HoverableTextUnderline: FC<IHoverableTextUnderlineProps> = (_props) => {
  const mergedProps = useOverrideComponentPropsContext('HoverableTextUnderline', _props)
  const { text, isHovered, hoveredBorderColor = 'primary', ...props } = mergedProps

  return (
    <VStack cursor="pointer" fontSize="sm" spacing={0} {...props}>
      <GrandText cursor="inherit" isTruncated>
        {text}
      </GrandText>
      <GrandDivider
        borderColor={isHovered ? hoveredBorderColor : 'transparent'}
        borderWidth={1}
        opacity={1}
        width={isHovered ? '100%' : 0}
      />
    </VStack>
  )
}

export default HoverableTextUnderline
